import {defineStore} from "pinia";
import type {Ref} from "vue";

export const useLayout = defineStore("layout", () => {

    const defaultValues = {
        step: 0,
        header: false,
        colored: false,
        footer: false,
        goBack: false,
        previousStep: false,
        nextStep: false,
        nextStepCheck: () => {
            return true
        }
    }

    const step = ref(defaultValues.step);
    const header: Ref<boolean | string> = ref(defaultValues.header);
    const colored: Ref<boolean> = ref(defaultValues.colored);
    const footer: Ref<boolean> = ref(defaultValues.footer);
    const goBack: Ref<boolean> = ref(defaultValues.goBack);
    const previousStep: Ref<string | boolean> = ref(defaultValues.previousStep);
    const nextStep: Ref<string | boolean> = ref(defaultValues.nextStep);
    const nextStepCheck: Ref<Function | boolean> = ref(defaultValues.nextStepCheck);

    const setDefaultValues = () => {
        step.value = defaultValues.step
        header.value = defaultValues.header
        colored.value = defaultValues.colored
        footer.value = defaultValues.footer
        goBack.value = defaultValues.goBack
        previousStep.value = defaultValues.previousStep
        nextStep.value = defaultValues.nextStep
        nextStepCheck.value = defaultValues.nextStepCheck
    }

    return {step, header, colored, previousStep, nextStep, footer, nextStepCheck, goBack, setDefaultValues};
})
